import React from "react"
import img_hero from "../assets/images/socal_hero_img.png"
import logo_hero from "../assets/images/socal_hero_logo.png"
import mental_health_img from "../assets/images/socal_mental_health.png"
import advocate_img1 from "../assets/images/socal_athlete.png"
import advocate_img2 from "../assets/images/socal_coach.png"

export const content = {
	// Hero Section
	hero: {
		logo: logo_hero,
		text: (
			<>
				Galea and SOCAL Soccer are excited to team up to provide athletes with holistic, proactive mental health and
				mental performance support.
				<br /> <br />
				“SOCAL recognizes that mental health and well-being are just as important as the physical fitness of the
				athlete. We want to help remove the stigma of what needing help looks like. We hope that through our partnership
				with Galea, we can provide our athletes a resource where they can talk to professionals in mental health that
				are former athletes and thus understand the pressures and expectations that today’s athletes face. We want every
				athlete to know that they are not alone. Help is available. You are important. You are valued. This is truly a
				unique partnership that we are proud to bring to our membership because we believe in you!”
				<br /> <br />– Michelle Chesters, SOCAL Executive Director
			</>
		),
		image: img_hero,
	},

	// Galea x SoCal Mental Health Advocate Programs
	AdvocateProg: {
		title: 
		<>
		   Galea <span>x</span> SoCal Mental Health Advocate Programs 
		</>,
		img1: advocate_img1,
		subtext1: "For athletes",
		text1: (
			<>
				The Galea x SOCAL advocate program empowers athletes to advocate for athlete mental health through education,
				peer support, and leadership training.
				<ul>
					<li>8 weeks</li>
					<li>1 hour virtual workshop with your athlete cohort covering different mental health topics each week</li>
					<li>
						Athletes who complete the program will be listed on Galea’s athlete advocate portal alongside SOCAL,
						collegiate, and professional athlete advocates
					</li>
				</ul>
			</>
		),
		img2: advocate_img2,
		subtext2: "For parents & coaches",
		text2: (
			<>
				Galea’s Parent & Coach Mental Health Awareness Program provides parents and coaches with tools and education to
				support athlete mental health and sport enjoyment.
				<ul>
					<li>4 weeks</li>
					<li>45 minute virtual workshop each week</li>
					<br />
					<br />
				</ul>
			</>
		),
	},

	// MENTAL HEALTH X SOCCER
	mentalHealth: {
		title: "Mental Health x Soccer",
		image: mental_health_img,
		text: (
			<>
				In the world of elite soccer, high prevalence of concussions and TBIs, the pressures of year-round competition,
				and common challenges like perfectionism, OCD, and burnout increase the stress that young soccer players
				experience. Players that suffer a serious injury are two to seven times more likely to report mental health
				conditions than their peers. Learning how to manage stress and prioritize mental health are crucial aspects of
				athlete development, emotional well-being, and athletic performance.
				<br /> <br />
				Galea offers SoCal athletes a network of licensed therapists, nutritionists, and certified mental performance
				coaches in California. Galea’s providers can support SoCal athletes at all levels, from youth to pro, as they
				navigate challenges and pursue their goals in soccer and beyond.
			</>
		),
	},

	// Learn More
	LearnMore: {
		title: "Interested in learning more?",
		text: "E-mail us with questions, concerns, or suggestions.",
	},
}
