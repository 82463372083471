import React from "react"
import { Main } from "../components/layout"
import HeroPacific from "../components/pages/pacific/HeroPacific"
import { content } from "../content/socal"
import LearnMore from "../components/pages/pacific/LearnMore"
import MentalHealth from "../components/pages/socal/MentalHealth"
import AdvocateProg from "../components/pages/socal/AdvocateProg"

const Socal = () => {
	return (
		<Main>
			<HeroPacific logo={content.hero.logo} text={content.hero.text} img={content.hero.image} />
			<AdvocateProg
				title={content.AdvocateProg.title}
				img1={content.AdvocateProg.img1}
				subtext1={content.AdvocateProg.subtext1}
				text1={content.AdvocateProg.text1}
				img2={content.AdvocateProg.img2}
				subtext2={content.AdvocateProg.subtext2}
				text2={content.AdvocateProg.text2}
			/>
			<MentalHealth
				title={content.mentalHealth.title}
				text={content.mentalHealth.text}
				image={content.mentalHealth.image}
			/>
			{/* <LearnMore title={content.LearnMore.title} text={content.LearnMore.text} /> */}
		</Main>
	)
}

export default Socal
