import React from "react"
import Section from "../../../../components/shared/Section"
import { Col, Container, Image, Row } from "react-bootstrap"
import Paragraph from "../../../../components/shared/Paragraph"
import * as styles from "./styles.module.scss"
import SubHeading from "../../../../components/shared/SubHeading"

const MentalHealth = ({ title, text, image }) => {
	return (
		<Section className={styles.bg}>
			<Container>
				<Row className="mt-md-5 mt-0 mb-5 pt-md-5 pt-0 pb-5">
					<Col md={{ span: 6, order: 1 }} xs={{ order: 2 }} className="mt-md-3 mt-5 ">
						<SubHeading className=" pt-0 pt-md-5 text-md-start text-center "> {title}</SubHeading>
						<Paragraph className="text-md-start text-center pt-3">{text}</Paragraph>
					</Col>
					<Col md={{ span: 5, offset: 1, order: 2 }} xs={{ order: 1 }}>
						<div className="text-center">
							<Image src={image} fluid width={450} />
						</div>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default MentalHealth
